<!--  -->
<template>
  <div class="OAapproval">
    <myApproval />
    <carList />
  </div>
</template>

<script>
import myApproval from "./components/myApproval";
import carList from "./components/carList";
export default {
  data() {
    return {};
  },
  name: "OAapproval",
  components: { myApproval, carList },
  computed: {},
  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
.OAapproval {
  min-height: 100%;
  background: #fafafa;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
</style>
