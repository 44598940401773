<!--  -->
<template>
  <div class="order-metting-ing">
    <div class="search-date">
      <p class="label">预定日期</p>
      <p class="picker-box">
        <van-cell :value="date" @click="showCalendar = true">
          <template #icon>
            <van-icon name="calendar-o" color="#999" :size="23" />
          </template>

          <template #right-icon>
            <van-icon name="arrow-down" color="#999" :size="16" />
          </template>
        </van-cell>
        <van-calendar v-model="showCalendar" @confirm="onConfirm" />
      </p>
    </div>
    <div class="main-panel">
      <ul class="list-box" v-if="list.length">
        <li
          class="card-item"
          v-for="(item, index) in list"
          :key="index"
          @click.prevent.stop="goDetail(item, index)"
        >
          <div class="car-info">
            <el-image
              :src="item.cartImgUrl"
              class="left"
              @click.prevent.stop=""
              :preview-src-list="[item.cartImgUrl]"
            />
            <div class="right">
              <div class="row1">
                <div>{{ item.cartName }}</div>
                <div>{{ item.cartStatus }}</div>
              </div>
              <div class="row2">
                <div class="seat">{{ item.seatNum }}座</div>
                <div class="cart-series">{{ item.platingNumber }}</div>
              </div>
              <div class="row3">油量：{{ item.fuelQuantity || 0 }}%</div>
            </div>
          </div>

          <ul class="bar-ul">
            <li
              v-for="(subItem, subIndex) in item.timeDetail"
              :key="'' + index + subIndex"
              class="bar-li"
            >
              <!-- 0已过期2预定 -->
              <div
                :class="{
                  bar: true,
                  gray: 0 == subItem.status,
                  darkGray: 2 == subItem.status,
                  blue: 3 == subItem.status,
                }"
                @click.prevent.stop="goApplyCar(item, index, subItem, subIndex)"
              ></div>
              <div class="num">{{ subItem.number }}</div>
            </li>
          </ul>
        </li>
      </ul>
      <van-empty v-else description="暂无车辆" />
    </div>
    <van-popup v-model="showImg">
      <van-image width="7.9rem" height="7.9rem" fit="fill" :src="imgUrl" />
    </van-popup>
  </div>
</template>

<script>
import { getAvailableList } from "@/api/cart/manage/index.js";
import { Toast } from "vant";

export default {
  data() {
    return {
      date: "",
      list: [],
      showCalendar: false,
      showImg: false,
      imgUrl: "",
    };
  },

  components: {},

  computed: {},
  filters: {},
  mounted() {
    const date = new Date();
    this.formatDate(date);
    this.getList();
  },

  methods: {
    showImgUrl(url) {
      this.showImg = true;
      this.imgUrl = url;
    },
    getList() {
      getAvailableList({
        date: this.date,
      }).then((res) => {
        this.list = res.data;
      });
    },
    formatDate(date) {
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, "0");
      let day = String(date.getDate()).padStart(2, "0");
      this.date = `${year}-${month}-${day}`;
    },
    onConfirm(date) {
      this.showCalendar = false;
      this.formatDate(date);
      this.getList();
    },
    goDetail(item, index) {
      this.$router.push({
        path: "/carDetail",
        query: {
          code: item.bizCode,
        },
      });
    },
    goApplyCar(item, index, sub, sunIndex) {
      if (sub.status == 0) {
        Toast("已过期");
      }  else if (sub.status == 2) {
        Toast(
          `已被${sub.userName || "-"}预定,去向：${sub.origin}用车事由:${
            sub.reason
          }`
        );
      } else if (sub.status == 3) {
        // Toast(`已被${sub.userName || "-"}预定`);
        Toast(
          `已被${sub.userName || "-"}预定,去向：${sub.origin}用车事由:${
            sub.reason
          },乘车人：${sub.passengers ? sub.passengers.toString() : "无"}`
        );
      } else {
        this.$router.push({
          // path: "/applyCar",
          path: "/approvalCreat",
          query: {
            platingNumber: item.platingNumber,
            cartCode: item.bizCode,
            date: this.date,
            bizCode: item.processCode,
            tagKey: "CAR_APPLY_APPROVE",
          },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.order-metting-ing {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 0.2rem;
  position: relative;
  .search-date {
    width: 100%;
    .label {
      height: 0.4rem;
      font-size: 0.28rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
    }
    .picker-box {
      border-bottom: 0.01rem solid #e5e5e5;
      padding: 0.14rem 0rem;
      .van-cell {
        padding: 0.14rem 0rem;
      }
      .van-cell__value {
        margin-left: 0.05rem;
        span {
          font-size: 0.3rem;
        }
      }
    }
  }
  .main-panel {
    padding: 0.3rem 0rem;
    width: 100%;
    .label {
      height: 0.4rem;
      font-size: 0.28rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
    }
    .list-box {
      width: 100%;
      margin-top: 0.3rem;
      .card-item {
        background: #ffffff;
        border-radius: 0.2rem;
        border: 0.01rem solid #0094ee;
        overflow: hidden;
        padding: 0.2rem;
        margin-bottom: 0.2rem;

        .car-info {
          padding: 0.2rem;
          display: flex;
          .left {
            width: 2rem;
            height: 1.4rem;
            margin-right: 0.2rem;
            border-radius: 0.08rem;
          }
          .right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .row1 {
              font-size: 0.3rem;
            }
            .row2 {
              display: flex;
              margin-top: 0.2rem;
              color: #666;
              font-size: 0.24rem;
              .seat {
                white-space: nowrap;
              }
              .cart-series {
                margin-left: 0.2rem;
              }
            }
            .row3 {
              margin-top: 0.2rem;
              color: #666;
              font-size: 0.24rem;
            }
          }
        }

        .bar-ul {
          display: grid;
          grid-template-columns: repeat(32, 3.1%);
          overflow: visible;
          .bar-li {
            width: 100%;
            flex: 1;
            overflow: visible;
            .bar {
              width: 100%;
              height: 0.32rem;
              background: #f1f5f6;
            }
            .bar.gray {
              background: #eaeaea;
            }
            .bar.darkGray {
              background: #aaa;
            }
            .bar.blue {
              background: #0094ee;
            }
            .num {
              font-size: 0.22rem;
              color: #999999;
              line-height: 0.3rem;
              height: 0.3rem;
              overflow: visible;
              white-space: nowrap;
              position: relative;
              left: -0.12rem;
            }
          }
          .bar-li:nth-of-type(1) {
            .bar {
              border-radius: 0.16rem 0 0 0.16rem;
            }
          }
          .bar-li:nth-last-of-type(1) {
            .bar {
              border-radius: 0 0.16rem 0.16rem 0;
            }
            .num {
              position: relative;
              left: 0.1rem;
            }
          }
        }
      }
    }
  }
}
.container {
  padding: 0.2rem 0.2rem 0 0.2rem;
  .title {
    height: 0.37rem;
    font-size: 0.3rem;
    color: #333333;
    line-height: 0.37rem;
    text-align: center;
    margin-bottom: 0.3rem;
    font-weight: bold;
  }
  .item {
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0.1rem 0;
    .sub-title {
      height: 0.37rem;
      font-size: 0.26rem;
      color: #333333;
      line-height: 0.37rem;
      margin-bottom: 0.1rem;
      .red {
        color: red;
      }
    }
    .input {
      height: 0.42rem;
      font-size: 0.3rem;
      color: #999999;
      line-height: 0.42rem;
    }
    .text {
      min-height: 0.42rem;
      font-size: 0.3rem;
      color: #999999;
      line-height: 0.42rem;
    }
  }
  .time {
    .checkbox-group {
      height: 5rem;
      overflow-y: scroll;
      .checkbox {
        height: 0.8rem;
        display: flex;
        align-items: center;
        border-top: 1px solid #f0f0f0;
        .check {
          flex: 1;
        }
        .tip {
          font-size: 0.24rem;
          color: #999;
        }
      }
    }
  }

  .bottom-buttons {
    background: #fff;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.1rem;
    .button {
      width: 2.4rem;
      height: 0.8rem;
      border-radius: 0.4rem;
      font-size: 0.26rem;
      line-height: 0.37rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cancel {
      background: rgba(0, 148, 238, 0.1);
      color: #0094ee;
      margin-right: 0.4rem;
    }
    .primary {
      background: #0094ee;
      color: #ffffff;
    }
  }
}
.van-image__img {
  width: 4rem;
  height: 4rem;
}
</style>
