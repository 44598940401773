<!--  -->
<template>
  <div class="myApproval">
    <div
      v-for="(item, index) in topList"
      :key="index"
      class="item"
      @click="goList(item, index)"
    >
      <div class="img-box">
        <img :src="item.icon" class="top-img" />
        <div class="red-point" v-if="item.count">{{ item.count }}</div>
      </div>
      <div class="text">{{ item.text }}</div>
    </div>
  </div>
</template>

<script>
import applyCar from "@/assets/images/carMgr/applyCar.png";
import backList from "@/assets/images/carMgr/backList.png";
import carBack from "@/assets/images/carMgr/carBack.png";
import useList from "@/assets/images/carMgr/useList.png";
import { getAvailableList } from "@/api/cart/manage/index.js";

export default {
  data() {
    return {
      topList: [
        { text: "申请用车", icon: applyCar, count: 0, path: "/approvalCreat" },
        { text: "还车登记", icon: carBack, count: 0, path: "/useList?type=1" },
        // { text: "用车记录", icon: backList, count: 0, path: "/useList" },
        { text: "还车记录", icon: useList, count: 0, path: "/backList" },
      ],
      curCar: null,
      date: "",
    };
  },
  name: "myApproval",
  components: {},
  computed: {},
  created() {},
  async mounted() {
    await this.formatDate(new Date());
    await this.getList();
  },
  methods: {
    formatDate(date) {
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, "0");
      let day = String(date.getDate()).padStart(2, "0");
      this.date = `${year}-${month}-${day}`;
      // console.log(this.date);
    },
    getList() {
      getAvailableList({
        date: this.date,
      }).then((res) => {
        if (res.data && res.data.length > 0) {
          this.curCar = res.data[0];
          // console.log(this.curCar);
        }
      });
    },
    goList(item, index) {
      if (index === 0) {
        this.$router.push({
          path: "/approvalCreat",
          query: {
            platingNumber: this.curCar.platingNumber,
            cartCode: this.curCar.bizCode,
            date: this.date,
            bizCode: this.curCar.processCode,
            tagKey: "CAR_APPLY_APPROVE",
          },
        });
      } else {
        this.$router.push({
          path: item.path,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.myApproval {
  height: 1.95rem;
  background-color: #ffffff;
  border-radius: 0.16rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;

  .item {
    display: flex;
    align-items: center;
    flex-direction: column;
    .img-box {
      margin-bottom: 0.12rem;
      height: 0.86rem;
      width: 0.86rem;
      border-radius: 0.5rem;
      margin-bottom: 0.04rem;
      background: #f0f0f0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .top-img {
        width: 100%;
        height: 100%;
      }
      .red-point {
        position: absolute;
        right: 3px;
        top: 0;
        transform: translateX(50%);
        min-width: 17px;
        height: 17px;
        font-size: 12px;
        color: #fff;
        border-radius: 8px;
        background: #f65f38;
        border: 1px solid #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 17px;
        box-sizing: border-box;
        white-space: nowrap;
        padding: 0 3px;
      }
    }
    .text {
      height: 0.37rem;
      font-size: 0.26rem;
      color: #333333;
      line-height: 0.37rem;
    }
  }
}
</style>
